import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showDetailPulse: true
  },
  mutations: {
    setShowDetailPulse(state, payload: boolean) {
      state.showDetailPulse = payload;
    }
  },
  actions: {
    markDetailAsSeen({ commit }) {
      commit('setShowDetailPulse', false)
    }
  },
  getters: {
    showDetailPulse(state): boolean {
      return state.showDetailPulse;
    }
  },
  modules: {
  }
})
