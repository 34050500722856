






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class PrimaryToClipboardButton extends Vue {
  private currentCopyIcon = "DuplicateIcon";

  clicked() {
    this.currentCopyIcon = "CheckmarkIcon";

    setTimeout(() => {
      this.currentCopyIcon = "DuplicateIcon";
    }, 1000);

    this.$emit("clicked");
  }
}
