





































































import { Component, Vue } from "vue-property-decorator";

// Don't forget to import the component either globally or locally, e.g.
// import SBaseButton from "@5amcode/sunrise-components";
// and include it in the decorator.

@Component
export default class ExampleSCircleTag extends Vue {}
