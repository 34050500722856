













































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SSimpleCard extends Vue {
  @Prop() title!: string;
  @Prop() author!: string;
  @Prop() category!: string;
  @Prop() date!: string;
  @Prop() read!: string;
  @Prop() imagePath!: string;
  @Prop() excerpt!: string;
  @Prop() tags!: Array<string>;
}
