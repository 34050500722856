






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class BaseToClipboardButton extends Vue {
  private currentCopyIcon = "DuplicateIcon";
  private currentCopyIconColor = "text-azure-500";

  clicked() {
    this.currentCopyIcon = "CheckmarkIcon";
    this.currentCopyIconColor = "text-amber-500";

    this.$emit("clicked");

    setTimeout(() => {
      this.currentCopyIcon = "DuplicateIcon";
      this.currentCopyIconColor = "text-azure-500";
    }, 1000);
  }
}
