































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ComponentRow extends Vue {
  @Prop({ required: true }) componentName!: string;

  private showCode = false;
}
