































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class STable extends Vue {
  @Prop() initialTableData!: [][];
  @Prop() testprop!: Array<object>;

  // column to show on responsive view
  mainColumn = 0;

  tableData: [][] = [];

  mounted() {
    this.tableData = this.initialTableData;
  }
}
