import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'
import Docs from '../views/Docs.vue'
import Usage from '../views/Usage.vue'
import Colors from '../views/Colors.vue'
import Roadmap from '../views/Roadmap.vue'
import ParentComponents from '../views/ParentComponents.vue'
import ParentTypography from '../views/ParentTypography.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    children: [
      {
        path: '/usage',
        name: 'Usage',
        component: Usage
      },
      {
        path: '/colors',
        name: 'Colors',
        component: Colors
      },
      {
        path: '/roadmap',
        name: 'Roadmap',
        component: Roadmap
      },
      {
        path: '/components',
        name: 'Components',
        component: ParentComponents,
        children: [
          {
            path: 'buttons',
            name: 'Button',
            component: () => import('../views/PreviewButton.vue')
          },
          {
            path: 'tabs',
            name: 'Tabs',
            component: () => import('../views/PreviewTabs.vue')
          },
          {
            path: 'timeline',
            name: 'Timeline',
            component: () => import('../views/PreviewTimeline.vue')
          },
          {
            path: 'cards',
            name: 'Cards',
            component: () => import('../views/PreviewCards.vue')
          },
          {
            path: 'lists',
            name: 'Lists',
            component: () => import('../views/PreviewLists.vue')
          },
          {
            path: 'tags',
            name: 'Tags',
            component: () => import('../views/PreviewTags.vue')
          },
        ]
      },
      {
        path: '/typography',
        name: 'Typography',
        component: ParentComponents,
        children: [
          {
            path: 'headlines',
            name: 'Headlines',
            component: () => import('../views/PreviewHeadlines.vue')
          },
          {
            path: 'paragraph',
            name: 'Paragraph',
            component: () => import('../views/PreviewParagraph.vue')
          },
          {
            path: 'links',
            name: 'Links',
            component: () => import('../views/PreviewLinks.vue')
          },
          {
            path: 'quotes',
            name: 'Quotes',
            component: () => import('../views/PreviewQuotes.vue')
          }
        ]
      },
    ]
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
