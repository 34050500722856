





















import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import ParentComponents from "./ParentComponents.vue";

@Component({
  components: { ParentComponents },
})
export default class extends Vue {
  isParent = false;

  @Watch("$route", { immediate: true, deep: true })
  pathChanged(newPath: Route) {
    if (newPath.name == "Docs") this.isParent = true;
    else this.isParent = false;
  }
}
