































import { Component, Vue } from "vue-property-decorator";
import NavigationLink from "./NavigationLink.vue";
import GithubButton from "vue-github-button";
import ComponentNavigation from "@/views/navigation/ComponentNavigation.vue";
import TypographyNavigation from "@/views/navigation/TypographyNavigation.vue";

@Component({
  components: {
    NavigationLink,
    GithubButton,
    ComponentNavigation,
    TypographyNavigation,
  },
})
export default class SunriseNavigation extends Vue {}
