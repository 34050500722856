





















import { Component, Vue, Prop } from "vue-property-decorator";
import ComponentNavigation from "./navigation/ComponentNavigation.vue";
import TypographyNavigation from "./navigation/TypographyNavigation.vue";

@Component({
  components: {
    ComponentNavigation,
    TypographyNavigation,
  },
})
export default class ParentComponents extends Vue {
  @Prop({ default: false }) wrapped!: boolean;

  get isParent() {
    console.log(this.wrapped);
    if (this.wrapped) return true;

    if (this.$route.name == "Components" || this.$route.name == "Typography")
      return true;
    else return false;
  }
}
