import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta';

import '@/assets/css/tailwind.css';


Vue.use(VueMeta);
Vue.config.productionTip = true

// register page-specific components globally
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName = "") => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})


import SunriseComponents from "@5amcode/sunrise-components";
Vue.component('SAzureButton', SunriseComponents.SAzureButton);
Vue.component('SAzureOutlineButton', SunriseComponents.SAzureOutlineButton);
Vue.component('SBaseButton', SunriseComponents.SBaseButton);
Vue.component('SBaseOutlineButton', SunriseComponents.SBaseOutlineButton);
Vue.component('SCard', SunriseComponents.SCard);
Vue.component('SAuthorCard', SunriseComponents.SAuthorCard);
Vue.component('SColorIconButton', SunriseComponents.SColorIconButton);
Vue.component('SHeadline', SunriseComponents.SHeadline);
Vue.component('SHeadlineAlternative', SunriseComponents.SHeadlineAlternative);
Vue.component('SIconButton', SunriseComponents.SIconButton);
Vue.component('SInlineQuote', SunriseComponents.SInlineQuote);
Vue.component('SLeadingParagraph', SunriseComponents.SLeadingParagraph);
Vue.component('SLink', SunriseComponents.SLink);
Vue.component('SGradientLink', SunriseComponents.SGradientLink);
Vue.component('SExternalLink', SunriseComponents.SExternalLink);
Vue.component('SParagraph', SunriseComponents.SParagraph);
Vue.component('SParagraphHeadline', SunriseComponents.SParagraphHeadline);
Vue.component('SAmberOutlineButton', SunriseComponents.SAmberOutlineButton);
Vue.component('SAmberButton', SunriseComponents.SAmberButton);
Vue.component('SQuote', SunriseComponents.SQuote);
Vue.component('SQuoteAuthor', SunriseComponents.SQuoteAuthor);
Vue.component('SQuoteCard', SunriseComponents.SQuoteCard);
Vue.component('SSimpleQuote', SunriseComponents.SSimpleQuote);
Vue.component('SSiteTitle', SunriseComponents.SSiteTitle);
Vue.component('STab', SunriseComponents.STab);
Vue.component('STabs', SunriseComponents.STabs);
Vue.component('STag', SunriseComponents.STag);
Vue.component('SOutlineTag', SunriseComponents.SOutlineTag);
Vue.component('SCircleTag', SunriseComponents.SCircleTag);
Vue.component('STimeline', SunriseComponents.STimeline);
Vue.component('STypoQuote', SunriseComponents.STypoQuote);


// create the vue app
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
