












































































































import { Component, Vue } from "vue-property-decorator";
import SunriseNavigation from "../PageSpecific/SunriseNavigation.vue";

@Component({
  components: { SunriseNavigation },
})
export default class extends Vue {
  showMobileMenu = false;
  menuButton = "";

  mounted() {
    this.menuButton = this.hamburger;
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;

    if (this.showMobileMenu == false) this.menuButton = this.hamburger;
    else this.menuButton = this.close;
  }

  hamburger = `<svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width="24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>`;

  close = `<svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="20"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>`;
}
