










import { Component, Vue } from "vue-property-decorator";
import TimelinePoint from "@5amcode/sunrise-components/src/TimelinePoint";

// Don't forget to import the component either globally or locally, e.g.
// import SBaseButton from "@5amcode/sunrise-components";
// and include it in the decorator.

@Component
export default class ExampleSTimeline extends Vue {
  private timelineArrangement = "left";

  private sampleEvents = [
    new TimelinePoint("Event 1", "Nov 01, 2020", `Description for Event 1`),
    new TimelinePoint("Event 2", "Nov 02, 2020", `Description for Event 2`),
    new TimelinePoint("Event 3", "Nov 03, 2020", `Description for Event 3`),
  ];
}
