



























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CodeModal from "./CodeModal.vue";

@Component
export default class ComponentBox extends Vue {
  @Prop({ required: true }) componentName!: string;
  @Action("markDetailAsSeen") markDetailAsSeen: any;
  @Getter("showDetailPulse") showDetailBadge!: boolean;

  $refs!: {
    codeModal: CodeModal;
  };

  private showCode = false;

  showComponentDetail() {
    this.showCode = true;
    this.markDetailAsSeen();
  }

  copy() {
    this.$refs.codeModal.copyToClipboard(null);
  }
}
