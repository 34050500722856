




import { Component, Vue } from "vue-property-decorator";
import PageLayout from "./components/PageSpecific/PageLayout.vue";

@Component({
  components: { PageLayout },
})
export default class App extends Vue {
  created() {
    document.title = "Sunrise Components by 5amco.de";
  }
}
