
















import { Component, Prop, Vue } from "vue-property-decorator";
import TimelinePoint from "@5amcode/sunrise-components/src/TimelinePoint";

@Component
export default class Roadmap extends Vue {
  roadmapEvents = Array<TimelinePoint>(
    new TimelinePoint(
      "Dark mode support",
      "Late 2020",
      "This is a no-brainer, right? While the colors look already great on dark screens, the code has to be adjusted a bit. Dark mode will be released with help of the new dark-classes introduced in Tailwind 2.0"
    ),
    new TimelinePoint("Forms", "Late 2020", "Everybody needs forms."),

    new TimelinePoint(
      "Lists",
      "Early 2021",
      "Make the display of monotonous, and maybe even boring, data beautiful and easy to understand, also on mobile devices. That's the goal!"
    ),
    new TimelinePoint(
      "More Components!",
      "Early 2021",
      "Alerts, Modals, Progress Bars, more Timelines and even whole sub-pages - All in the pipeline!"
    )
  );

  mounted() {
    // this.roadmapEvents = [];
  }
}
