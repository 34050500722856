






































import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class Breadcrumb extends Vue {
  private crumbs = new Array<object>();

  private showCrumbs = true;

  @Watch("$route", { immediate: true, deep: true })
  pathChanged(newPath: Route) {
    if (newPath.name == "Home") this.showCrumbs = false;
    else this.showCrumbs = true;

    // build breadcrumbs
    this.crumbs = [];
    for (const route of newPath.matched) {
      this.crumbs.push({
        link: route.path,
        label: route.name,
      });
    }
  }
}
