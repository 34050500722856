
export default class ComponentCodeService {

    private validComponents: Array<string> = [];
    private validExamples: Array<string> = [];

    constructor() {
        this.loadValidComponents().then();
        this.loadValidExamples().then().catch((err) => { console.log(err) });
    }


    isValidComponent(componentName: string): boolean {
        return this.validComponents.includes(componentName);
    }

    isValidExample(componentName: string): boolean {
        return this.validExamples.includes(componentName);
    }

    async loadValidComponents() {
        const getComponents = require.context("../../node_modules/@5amcode/sunrise-components/src", false, /([a-z_]+)\.vue$/i);
        const modules = getComponents
            .keys()
            .map((key) => ({ key, name: key.match(/([a-z_]+)\.vue$/i)[1] }))
            .reduce(
                (modules, { key, name }) => ({
                    ...modules,
                    [name]: getComponents(key).default
                }),
                {}
            )


        for (const mod in modules) {
            this.validComponents.push(mod);
        }

    }


    async loadValidExamples() {
        const context = require.context("../examples", false, /([a-z_]+)\.vue$/i);
        const modules = context
            .keys()
            .map((key) => ({ key, name: key.match(/([a-z_]+)\.vue$/i)[1] }))
            .reduce(
                (modules, { key, name }) => ({
                    ...modules,
                    [name]: context(key).default
                }),
                {}
            )


        for (const mod in modules) {
            this.validExamples.push(mod);
        }

    }


    static getComponentTitle(snippetKey: string): string {

        switch (snippetKey) {
            case 'PrimaryButton':
                return "Primary Button";
            default:
                return "Component Title";
        }
    }
    static getComponentText(snippetKey: string): string {

        const defaultText = "";
        let customText = "";

        switch (snippetKey) {
            case 'PrimaryButton':
                customText = `Some text about the component or general advise about
        copy/pasteing. Potato!`;
                break;
            case 'SCard':
                customText = `The SCard-Component can be filled with content in two ways: Either props or named slots. You decide!`;
                break;
            case 'SAuthorCard':
                customText = `The SAuthorCard-Component can be filled with content in two ways: Either props or named slots. You decide!`;
                break;
            case 'STimeline':
                customText = `The STimeline-Component for a vertical timeline can be conveniently used with props. You don't have to copy and paste the event blocks for every date or even write a logic by yourself. Just pass an Array with Objects of the class <code>TimelinePoint</code> via the <code>timelinePoints</code>-Prop to the component and it handles the rendering for you! <br />You can also choose the arrangement of the timeline dots (left or right) by the <code>arrangement</code> prop.`
                break;
            case 'STag':
                customText = `The SBase-Component can be customized: Choose text color and
                        background color. If you don't set the <code>textColor</code> and
                        <code>color</code> prop, the
                        <span class="font-semibold text-azure-500">azure</span> scheme is
                        used. If you add a <code>href</code> attribute to this Component,
                        there will be added a cursor-pointer automatically. <br/> And there's even more customization possible: You can icons on the left and right side with the corresponding slot. Check out the example for more!`;
                break;
            case 'SOutlineTag':
                customText = `The SOutlineBase-Component can be customized: Choose text color and
                                border color. If you don't set the <code>textColor</code> or
                                <code>borderColor</code> prop, the
                                <span class="font-semibold text-azure-500">azure</span> scheme is
                                used. If you add a <code>href</code> attribute to this Component,
                                there will be added a cursor-pointer automatically. <br/> And there's even more customization possible: You can icons on the left and right side with the corresponding slot. Check out the example for more!`;
                break;
            case 'SCircleTag':
                customText = `The SCircleTag can be used with an individual icon and custom text- and background colors. The color of the icon can be chosen as well; if you using a SVG-icon, specify a class with a text-color property. If you don't set the <code>textColor</code>, <code>iconColor</code> or <code>borderColor</code> prop, the <span class="font-semibold text-azure-500">azure</span> scheme is used. If you add a <code>href</code> attribute to this Component, there will be added a cursor-pointer automatically.`;
                break;
            default:
                customText = "";
                break;

        }
        if (customText != "") customText = customText + "<br />";

        return customText + defaultText;

    }



    async getExampleCode(componentName: string): Promise<string | null> {

        let exampleName = componentName;

        if (componentName.endsWith("Button")) {
            exampleName = "Button";
        }

        if (!this.isValidExample("Example" + exampleName)) {
            return "";
        }

        else {
            const component = await import(`!!raw-loader!../../src/examples/Example${exampleName}.vue`);
            return component.default;
        }

    }


    async getCode(componentName: string): Promise<string> {

        if (!this.isValidComponent(componentName)) {
            return "An error occured";
        }

        else {
            const component = await import(`!!raw-loader!../../node_modules/@5amcode/sunrise-components/src/${componentName}.vue`);
            return component.default;
        }
    }

}


