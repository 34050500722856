























import { Component, Vue } from "vue-property-decorator";

@Component
export default class SModal extends Vue {
  escPressed() {
    this.$emit("closed");
  }
}
