









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavigationLink extends Vue {
  @Prop({ required: true })
  private to!: string;
}
