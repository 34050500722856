















































import { Component, Prop, Vue } from "vue-property-decorator";
import CodeComponentService from "../../services/componentCodeService";
import Prism from "prismjs";

@Component
export default class CodeModal extends Vue {
  @Prop({ required: true }) componentName!: string;

  private codeComponentService!: CodeComponentService;

  private exampleCode = "";
  private code = "";
  private codeFormatted = "";
  private exampleCodeFormatted = "";
  private codeStyle = "border-azure-200";
  private showExampleTab = false;

  get componentText() {
    return CodeComponentService.getComponentText(this.componentName);
  }

  created() {
    this.codeComponentService = new CodeComponentService();

    this.codeComponentService.getCode(this.componentName).then((result) => {
      this.code = result;

      this.codeFormatted = Prism.highlight(
        result,
        Prism.languages.html,
        "html"
      );
    });

    this.codeComponentService
      .getExampleCode(this.componentName)
      .then((exampleResult) => {
        if (exampleResult != null && exampleResult != "") {
          this.exampleCode = exampleResult;

          this.exampleCodeFormatted = Prism.highlight(
            exampleResult,
            Prism.languages.html,
            "html"
          );

          this.showExampleTab = true;
        }
      });
  }

  closeModal() {
    this.$emit("closed");
  }

  public async copyToClipboard(codeToCopy: string | null) {
    if (codeToCopy == null) codeToCopy = this.code;

    this.codeStyle = "border-azure-300";

    navigator.clipboard.writeText(codeToCopy).then(
      () => {
        setTimeout(() => {
          this.codeStyle = "border-azure-200";
        }, 900);
      },
      () => {
        alert("Something went wrong.");
      }
    );
  }
}
