



































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Usage extends Vue {
  theme = `...
// theme fonts 
fontFamily: { 
    'display': ["'Source Sans Pro'"], // headlines 
    'sans': ["'Open Sans'"], // standard text 
    'oblique': ["'Source Serif Pro'"], // oblique, fancy text 
}, 
...
`;
}
